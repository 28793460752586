<script setup>
import { defineEmits } from 'vue';
import AdaptableButton from './AdaptableButton.vue';

const emits = defineEmits(['update:typeUserSelected', 'update:step']);

// const toDelivererForm = () => {
//     emits('update:typeUserSelected', 'deliver');
//     emits('update:step', 1);
// }

// funcion redirige a usuario a login
const toUserLogin = () =>{
    emits('update:typeUserSelected', 'user');
}

</script>

<template>
    <div class="space-y-8">
        <AdaptableButton
            :text="'Ingresar usuario'"
            :bgColor="'[#FFB800]'"
            :textColor="'black'"
            @input-function="toUserLogin"
        />
    </div>
</template>