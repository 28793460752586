<script setup>
import { ref, onMounted, watch, toRaw } from 'vue';
import LoginCreateAccount from './LoginCreateAccount.vue';
import InstalationSelector from './InstalationSelector.vue';
import UserSearch from './UserSearch.vue';
import DeptoSelect from './DeptoSelect.vue';
import SelectPackage from './SelectPackage.vue';
import PackageSaved from './PackageSaved.vue';
import TypeUserButtons from './TypeUserButtons.vue';
import LogoComponent from './LogoComponent.vue';
import NewFooter from './NewFooter.vue';
import axios from 'axios';
import RejectedText from '@/components/modalboxs/RejectedText.vue';
import UniversalModal from '@/components/ui/UniversalModal.vue';
import LoadingComponent from '@/components/loading/LoadingComponent.vue';
import DeliveryVoucher from './DeliveryVoucher.vue';

//variables de navegación
const typeUserSelected = ref('');
const intalationSelected = ref('');
const deptoSelected = ref('');
const userSelected = ref('');
const packageSelected = ref('');
const viewVoucher = ref('');
const confirmDelivery = ref('');

//datos de voucher
const addressName = ref('');
const userName = ref('');
const lockerType = ref('');
const nroDpto = ref('');

//variables operación
const step = ref(0);
const nroSerie = ref(''); //número de seria de dispositivo asociado a rack seleccionado
const typeLockersAvailable = ref({}); //identificador de lockers disponibles
const socket = ref(null); //variable almacenara instancia de web socket
const timeoutTimer = ref(null);
const showModal = ref(false);
const showLoading = ref(false);
const confirm = ref(false);

// maneja funcionalidad de botón 
const backButton = () => {
    
    // cambia de vista de confirmación a home
    if(step.value === 0){
        deleteSelections();
    // retroceder desde seleccion de instalación a home
    } else if (step.value === 1){ // vista seleccionar instalación
        // borrar instalación seleccionada
        intalationSelected.value = '';
        typeUserSelected.value = '';
        addressName.value = '';
        step.value = step.value - 1;
    } else if (step.value === 2){ // vista buscar usuario
        step.value = step.value - 1;
        deptoSelected.value = '';
    // retroceder desde seleccion de departamento a seleccion de instalación
    }  else if(step.value === 3){
        step.value = step.value - 1;
        userSelected.value = '';
        nroDpto.value = '';
    // retroceder desde selección de paquete a selección de departamento
    } else if (step.value === 4){
        step.value = step.value - 1;
        lockerType.value = '';        
        packageSelected.value = '';
    // retroceder desde vista voucher a selección de paquete
    } else if (step.value === 5){
        step.value = step.value - 1;
        lockerType.value = '';
        packageSelected.value = '';
        viewVoucher.value = '';
    } else if (step.value === 6){
        step.value = 0;
        deleteSelections();
    }
}

// guarda numero de serie e invoca a funcion que trae tipo de locker disponible
const getNroSerie = async (num) => {
    nroSerie.value = num;
    typeLockersAvailable.value = await getTypeLockersAvailable(num);
}

// obtiene objeto con tipo de locker disponibles e id del mismo
const getTypeLockersAvailable = async (nroSerie) => {
    // const url = `${process.env.VUE_APP_URL_CERRADURAS}/arriendo/tipo-lockers-disponibles?nro_serie=${nroSerie}`
    const url = `${process.env.VUE_APP_URL_CERRADURAS}/locker?nro_serie=${nroSerie}`
    return axios
            .get(url)
            .then((response) => {
                return response.data;
            });
}

// Función envia mensaje a web socket
const openlocker = () => {
    // parametros
    const request = {
        id_tipo_locker: toRaw(packageSelected.value),
        correo: toRaw(userSelected.value),
        nro_serie: toRaw(nroSerie.value),
        type: 'REPARTIDOR-ODIHNX',    
    }
    // envio de mensaje
    socket.value.send(JSON.stringify(request));

    // mostrar mensaje loading
    showLoading.value = true;

    //si en 10 segundos no hay mensajes exitosos, mostrar modal de error
    timeoutTimer.value = setTimeout(changeShowModal, 20000);

}

//Funciones para cambios de estado
const changeShowModal = () => {
    showLoading.value = false;
    showModal.value = !showModal.value;    
}

const deleteSelections = () => {
    showModal.value = false;
    step.value = 0;
    // variables de navegación
    typeUserSelected.value = "";
    intalationSelected.value = "";
    userSelected.value = "";
    packageSelected.value = "";
    viewVoucher.value = "";
    confirmDelivery.value = "";

    // variables de voucher
    addressName.value = "";
    userName.value = "";
    lockerType.value = "";

};

// luego del renderizado de componentes, se instancia web socket
onMounted(() => {
    socket.value = new WebSocket(`${process.env.VUE_APP_URL_SOCKET}`);

    socket.value.onmessage = (e) => {
      try {
        const jsonData = JSON.parse(e.data);
        // Mensaje recibido ok
        if (jsonData.messageDevice && jsonData.code == 200) {
            showLoading.value = false; //Oculto loading
            clearTimeout(timeoutTimer.value); // elimina setTimeout previo (no se muestra modal de error)
            confirm.value = true;
        // Mensaje con excepción desde servidor    
        } else {
          console.log(`solicitud: ${JSON.stringify(jsonData)}`);
        }

      } catch (error) {
        if (error instanceof SyntaxError) {
          console.log(
            'JSON string is not in the correct format:',
            error.message
          )
        } else {
          console.log('Error parsing JSON:', error.message);
        }
      }
    }
});

//observo valiable de guardado, para abrir locker (mensaje a web socket)
watch(confirmDelivery, () => {
    if (confirmDelivery.value){
        openlocker(); //enviar mensaje a web socket
    }
    
});

</script>

<template>
    <div class="flex flex-col items-center min-h-screen bg-[#0064ff]">

        <div class="max-w-md mt-8 space-y-8 px-6 flex-grow w-full">

            <!-- Botón de regreso y logo -->
            <div class="grid grid-cols-5 items-center">
                <!-- Primer espacio: Botón -->
                <button 
                    v-if="step !== 0"
                    @click="backButton"
                    class="mb-auto flex h-12 w-12 justify-center items-center rounded-md border border-transparent 
                            hover:bg-blue-300 bg-[#FFB800] py-2 px-4 text-sm text-black font-bold group">
                    <span class="text-lg">&lt;</span>
                </button>
                <!-- Primer espacio: Vacío si se completo el formulario -->
                <div v-if="addressName && !viewVoucher && !confirmDelivery" class="flex text-white space-x-2 w-max">
                    <h3>Ubicación:</h3>
                    <h3 class="font-semibold">
                        {{  
                            addressName && addressName.length > 25 ?
                            addressName.substring(0, 25) + " ..." :
                            addressName 
                        }}
                    </h3>
                </div>

            </div>
            
            <div class="grid grid-cols-7">
                <div class="col-span-1"></div>
                <LogoComponent v-if="step === 0 && typeUserSelected !== 'user'" class="col-span-5"/>
            </div>

            <!-- Texto si websocket falla -->
            <UniversalModal @cancel="deleteSelections" :isOpen="showModal">
                <RejectedText v-if="showModal" :hideModal="deleteSelections"/>
            </UniversalModal>
            <LoadingComponent class="object-none" v-if="showLoading" />


            <!-- Formulario -->
            <div v-else class="flex-grow">

                <TypeUserButtons 
                    v-if="step === 0 && typeUserSelected !== 'user'"
                    :typeUserSelected="typeUserSelected"
                    @update:step="value => step = value" 
                    @update:typeUserSelected="value => typeUserSelected = value"
                />

                <div v-if="typeUserSelected === 'user'">
                    <LoginCreateAccount/>
                </div>
                <div v-else>
                    <InstalationSelector     
                        @update:intalationSelected="value => intalationSelected = value" 
                        @update:addressName="value => addressName = value" 
                        @update:step="value => step = value" 
                        @get-nro-serie="getNroSerie"         
                        v-if="step === 1"
                    />
                    <DeptoSelect 
                        :intalationSelected="intalationSelected"
                        @update:deptoSelected="value => deptoSelected = value" 
                        @update:step="value => step = value" 
                        @update:nroDpto="value => nroDpto = value" 
                        v-else-if="step === 2"
                    />
                    <UserSearch
                        :deptoSelected="deptoSelected"
                        @update:userName="value => userName = value" 
                        @update:userSelected="value => userSelected = value" 
                        @update:step="value => step = value" 
                        v-else-if="step === 3"
                    />

                    <SelectPackage
                        :typeLockersAvailable="typeLockersAvailable"
                        :lockerType="lockerType"
                        @update:lockerType="value => lockerType = value" 
                        @update:packageSelected="value => packageSelected = value" 
                        @update:viewVoucher="value => viewVoucher = value" 
                        @update:step="value => step = value" 
                        v-else-if="step === 4"
                    />

                    <DeliveryVoucher
                        :addressName="addressName"  
                        :userName="userName"
                        :lockerType="lockerType"
                        :nroDpto="nroDpto"
                        @update:confirmDelivery="value => confirmDelivery = value" 
                        @update:step="value => step = value" 
                        v-else-if="step === 5"
                    />

                    <PackageSaved
                        v-else-if="step === 6 && confirm"
                    />

                </div>
            </div>
            
        </div>

        <NewFooter/>
        
    </div>
</template>