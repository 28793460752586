import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '@/views/LoginView.vue'
import NewAccount from '@/views/NewAccount.vue'
import NotFoundView from '@/views/NotFoundView.vue'
import MobileLayoutView from '@/views/MobileLayoutView.vue'
import InputPassView from '@/views/InputPassView.vue'
import CreateUserForm from '@/components/forms/CreateUserForm.vue'
import CreateInstallation from '@/components/forms/CreateInstallationForm.vue'
import UsersTable from '@/components/tables/UsersTable.vue'
import LockersTable from '@/components/tables/LockersTable.vue'
import InstallationTable from '@/components/tables/InstallationsTable.vue'
import ActiveTable from '@/components/tables/ActiveTable.vue'
import HistoryTable from '@/components/tables/HistoryTable.vue'
import SuccessOpenView from '@/views/SuccessOpenView.vue'
import FailureOpenView from '@/views/FailureOpenView.vue'
import SpentCodeView from '@/views/SpentCodeView.vue'
import InvitationView from '@/views/InvitationView.vue'
import EntitiesMenu from '@/components/menus/EntitiesMenu.vue'
import DevicesTable from '@/components/tables/DevicesTable.vue'
import CreateDevice from '@/components/forms/CreateDeviceForm.vue'
import SimcardsTable from '@/components/tables/SimcardsTable.vue'
import CreateSimcard from '@/components/forms/CreateSimcardForm.vue'
import RacksTable from '@/components/tables/RacksTable.vue'
import CreateRack from '@/components/forms/CreateRackForm.vue'
import CompaniesTable from '@/components/tables/CompaniesTable.vue'
import QrTable from '@/components/tables/QrTable.vue'
import CreateQrForm from '@/components/forms/CreateQrForm.vue'
import CreateCompany from '@/components/forms/CreateCompanyForm.vue'
import MobileMenu from '@/components/menus/MobileMenu.vue'
import OpenLocker from '@/components/OpenLocker.vue'
import ProfileComponent from '@/components/ProfileComponent.vue'
import ScheduleComponent from '@/components/ScheduleComponent.vue'
import CompanyDetail from '@/components/CompanyDetailComponent.vue'
import UnauthorizedView from '@/views/UnauthorizedView.vue'
import Auth from './middleware/auth.js'
import Rbac from './middleware/Rbac.js'
import PasswordChanger from '@/components/forms/ChangePasswordForm.vue'
import EditProfileForm from '@/components/forms/EditProfileForm.vue'
import finishRegister from '@/components/forms/FinishRegister.vue'
import AdminMenu from '@/components/menus/AdminMenu.vue'
import MailSender from '@/components/MailSender.vue'
import InstalationUsers from '@/components/InstalationUsers.vue'
import ReservationsTable from '@/components/tables/ReservationsTable.vue'
import DevicesStatus from '@/components/DevicesStatus.vue'
import DualStartVue from '@/components/delivery/DualStart.vue'
import DeliveryManagement from '@/components/DeliveryManagement.vue'
import RedirectDeliveryVue from '@/components/delivery/RedirectDelivery.vue'
import StartWithQrVue from '@/components/delivery/StartWithQr.vue'
import NotificationServices from '@/components/NotificationServices.vue'
import WspSender from '@/components/WspSender.vue'
import RedirectWsp from '@/components/delivery/RedirectWsp.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DualStartVue
  },
  {
    path: '/inst/:qr',
    name: 'RedirectDelivery',
    component: RedirectDeliveryVue
  },
  {
    path: '/redirect/:nro',
    name: 'RedirectWsp',
    component: RedirectWsp
  },
  {
    path: '/:id/:name/:device',
    name: 'StartWithQrVue',
    component: StartWithQrVue
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Error 404',
    component: NotFoundView
  },
  {
    path: '/open',
    name: 'InputPassView',
    component: InputPassView,
    props: true
  },
  {
    path: '/unauthorized',
    name: 'UnauthorizedView',
    component: UnauthorizedView
  },
  {
    path: '/register',
    name: 'NewAccount',
    component: NewAccount
  },
  {
    path: '/menu',
    name: 'MobileLayoutView',
    component: MobileLayoutView,
    meta: {
      reqAuth: true
    },
    children: [
      { path: '', name: 'HomeMenuView', component: MobileMenu },
      { path: 'newpassword', name: 'PassWordChanger', component: PasswordChanger },
      { path: 'rent', name: 'ReservationView', component: OpenLocker },
      { path: 'active', name: 'ActiveTable', component: ActiveTable },
      { path: 'history', name: 'HistoryView', component: HistoryTable },
      { path: 'entities', name: 'EntitiesMenuView', meta: { rbac: true }, component: EntitiesMenu },
      { path: 'admin', name: 'AdminMenu', meta: { rbac: true }, component: AdminMenu },
      { path: 'reservations', name: 'ReservationView', component: ReservationsTable },
      // { path: 'admin/mail', name: 'MailSender', meta: { rbac: true }, component: MailSender },
      { path: 'admin/services', name: 'NotificationServices', meta: { rbac: true }, component: NotificationServices },
      { path: 'admin/services/wsp', name: 'WspSender', meta: { rbac: true }, component: WspSender },
      { path: 'admin/services/mail', name: 'MailSender', meta: { rbac: true }, component: MailSender },
      { path: 'admin/reservation', name: 'ReservationTable', meta: { rbac: true }, component: ReservationsTable},
      { path: 'admin/devices', name: 'DevicesStatus', meta: { rbac: true }, component: DevicesStatus},
      { path: 'admin/delivery', name: 'DeliveryManagement', meta: { rbac: true }, component: DeliveryManagement},
      { path: 'admin/users', name: 'InstalationUsers', meta: { rbac: true }, component: InstalationUsers},
      { path: 'entities/users/new', name: 'CreateUsersView', meta: { rbac: true }, component: CreateUserForm },
      { path: 'entities/users/:user', name: 'ProfileComponentView', component: ProfileComponent },
      { path: 'entities/users/:user/edit', name: 'EditUsersView', meta: { rbac: true }, component: CreateUserForm },
      { path: 'entities/users/:user/edit/profile', name: 'EditProfileView', component: EditProfileForm },
      { path: 'entities/users', name: 'ShowUsersView', meta: { rbac: true }, component: UsersTable },
      { path: 'entities/lockers', name: 'ShowLockersView', meta: { rbac: true }, component: LockersTable },
      { path: 'entities/installations', name: 'ShowInstallationsView', meta: { rbac: true }, component: InstallationTable },
      { path: 'entities/installations/new', name: 'CreateInstallationsView', meta: { rbac: true }, component: CreateInstallation },
      { path: 'entities/installations/:installation/edit', name: 'EditInstallationsView', meta: { rbac: true }, component: CreateInstallation },
      { path: 'entities/devices', name: 'ShowDevicesView', meta: { rbac: true }, component: DevicesTable },
      { path: 'entities/devices/new', name: 'CreateDevicesView', meta: { rbac: true }, component: CreateDevice },
      { path: 'entities/devices/:device/edit', name: 'EditDevicesView', meta: { rbac: true }, component: CreateDevice },
      { path: 'entities/simcards', name: 'ShowSimcardsView', meta: { rbac: true }, component: SimcardsTable },
      { path: 'entities/simcards/new', name: 'CreateSimcardsView', meta: { rbac: true }, component: CreateSimcard },
      { path: 'entities/simcards/:simcard/edit', name: 'EditSimcardsView', component: CreateSimcard },
      { path: 'entities/racks', name: 'ShowRacksView', meta: { rbac: true }, component: RacksTable },
      { path: 'entities/racks/new', name: 'CreateRacksView', meta: { rbac: true }, component: CreateRack },
      { path: 'entities/racks/:rack/edit', name: 'EditRacksView', meta: { rbac: true }, component: CreateRack },
      { path: 'entities/companies', name: 'ShowCompaniesView', meta: { rbac: true }, component: CompaniesTable },
      { path: 'entities/companies/new', name: 'CreateCompaniesView', meta: { rbac: true }, component: CreateCompany },
      { path: 'entities/companies/:company/edit', name: 'EditCompaniesView', meta: { rbac: true }, component: CreateCompany },
      { path: 'entities/companies/:company', name: 'ShowCompanyView', meta: { rbac: true }, component: CompanyDetail },
      { path: 'entities/qrs', name: 'ShowQrsView', meta: { rbac: true }, component: QrTable },
      { path: 'entities/qrs/new', name: 'CreateQrView', meta: { rbac: true }, component: CreateQrForm },
      { path: 'entities/qrs/:qr/edit', name: 'EditQrsView', meta: { rbac: true }, component: CreateQrForm },
      { path: 'schedule', name: 'ScheduleView', component: ScheduleComponent },
      { path: 'finishInfo', name: 'finishRegister', component: finishRegister }

    ]
  },
  {
    path: '/open/success',
    name: 'SuccessOpenView',
    props: true,
    component: SuccessOpenView
  },
  {
    path: '/open/failure',
    name: 'FailureOpenView',
    props: true,
    component: FailureOpenView
  },
  {
    path: '/open/spent',
    name: 'SpentCodeView',
    props: true,
    component: SpentCodeView
  },
  {
    path: '/open/invitation',
    name: 'InvitationView',
    props: true,
    component: InvitationView
  },
  {
    path: '/test',
    name: 'DualStartVue',
    props: true,
    component: DualStartVue
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from) => { // eslint-disable-line
  if (to.meta.reqAuth && !Auth()) return true
  if (to.meta.rbac) Rbac()
  return true
})

export default router
