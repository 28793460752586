<script setup>

</script>

<template>
    <div>
        <div class="py-8 grid justify-items-center text-center ">
            <h2 class="mb-10 text-xl font-semibold text-white"> Guardar pedido! </h2>
            <img 
                class="block h-16 w-16 cursor-pointer" 
                src="@/assets/package.png"
            />
            <h3 class="mt-8 text-md font-semibold text-white"> Se notificara al usuario que el  </h3>
            <h3 class="text-md font-semibold text-white"> pedido a sido entregado!  </h3>
            <h3 class="mt-8 text-md font-semibold text-white"> Asegúrese de cerrar el locker.  </h3>
        </div>
    </div>
</template>