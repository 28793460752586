<template>
  <div class="min-h-screen flex flex-col justify-between grow bg-[#0064ff]">
    <div></div>
    <div class="flex h-full items-center justify-center">
      <div class="max-w-md space-y-8 p-4 mt-5 mx-4">
        <div>
          <img class="mx-auto mt-10 h-12 w-auto cursor-pointer" @click="$router.push('/')" src="../assets/odihnx.png"
            alt="Odihnx" />
          <h2 class="text-center text-2xl mt-6 font-bold tracking-tight text-white cursor-default">
            Crea tu cuenta
          </h2>
          <div v-if="message" class="mt-4 mb-4 text-center text-xl font-bold tracking-tight text-white bg-red-600 border rounded-md">
            <div class="grid mt-2 justify-items-end px-2">
              <button @click="changeMessageStatus">
                <img src="../assets/close.png" class="rounded-md max-h-5 max-w-5"/>
              </button>
            </div>            
            <h2>
              {{ message }}
            </h2>
          </div>
          
        </div>
        <form class="mt-8 space-y-6" v-on:submit="register">
          <div class="-space-y-px rounded-md shadow-sm">
            <div>
              <input name="email" v-model="user" autocomplete="email" required
                class="relative block w-full bg-white rounded my-3 border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm "
                placeholder="Correo Electrónico" />
            </div>
            <div>
              <input name="password" v-model="password" type="password" autocomplete="current-password" required
                class=" relative block w-full appearance-none rounded border-gray-300 my-3 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                placeholder="Contraseña" />
            </div>
          </div>

          <div class="flex flex-col justify-items-center">
            <ReCaptcha 
              v-model:recaptchaToken="recaptchaToken"
            />

            <button 
              type="submit"
              :disabled="!recaptchaToken" 
              class="relative flex w-full justify-center rounded-md border border-transparent hover:bg-blue-300 bg-black py-2 px-4 text-sm text-white group">
              <LoadingComponent class="object-none" v-if="isLoading" />
              <span v-else> Crear cuenta </span>
            </button>
            <h2 class="text-center my-3 text-white cursor-pointer" @click="$router.push({ name: 'Home' })">
              ¿Ya tienes cuenta? <u class="text-white font-bold"> Inicia sesión </u>
            </h2>
            <div class="flex flex-row justify-evenly items-center my-2">
              <hr class="border-black grow mx-2" />
              <p class="text-center text-white mx-2">O</p>
              <hr class="border-black grow mx-2" />
            </div>
            <GoogleLogin :callback="googleRegister" class="my-2 w-full flex flex-row">
              <button
                class="relative flex w-full justify-left items-center rounded-md border border-transparent hover:bg-gray-300 bg-white py-2 px-4 text-sm">
                <img src="@/assets/g_logo.svg" class="ml-2 rounded-md" />
                <p class="ml-3 text-gray-700"> Continuar con Google</p>
              </button>
            </GoogleLogin>
          </div>
        </form>
        
        <div 
          v-if="modalCodeStatus" 
          
          class="fixed inset-0 flex items-center justify-center z-50"
        >
          <div @click="changeCodeModalStatus" class="bg-gray-800 bg-opacity-30 absolute inset-0"></div>
          <div 
            class="modal-content p-4 bg-white shadow-lg z-10
            border border-slate-400 rounded-md">
            <ConfirmCode 
                @send-code="sendCode"
            />
          </div>
        </div>
        
      </div>
    </div>
    <div />
    <div />
    <FooterComponent />

  </div>
</template>

<script>
import axios from 'axios'
import LoadingComponent from '@/components/loading/LoadingComponent.vue';
import FooterComponent from '@/components/ui/FooterComponent.vue';
import ReCaptcha from '@/components/ReCaptcha.vue';
import ConfirmCode from '@/components/modalboxs/ConfirmCode.vue';
export default {
  name: 'NewAccount',
  props: {
    msg: String
  },
  data () {
    return {
      user: '',
      password: '',
      isLoading: false,
      message: '',
      recaptchaToken: '',
      //recaptchaToken: 'asd',
      modalCodeStatus: false
    }
  },
  methods: {
    async register (e) {
      e.preventDefault();
      this.isLoading = true
      try {
        const registerResponse = await axios.post(
          process.env.VUE_APP_URL_BACKEND + '/auth/registrarse',
          {
            apellido: '',
            casa_depto: '',
            direccion: '',
            email: this.user,
            instalacion: '',
            nombre: '',
            password: this.password,
            password_pregunta_secreta: '',
            phone: '',
            pregunta_secreta: '',
            username: this.user,
            recaptchaAction: '',
            recaptchaToken: this.recaptchaToken
          }
        ).catch((error) =>{
          if (error.response.status === 409){
            this.message = 'Recapcha invalido';
            this.setTime(); 
            this.isLoading = false
            return false;
          } else if (error.response.status === 400){
            this.message = 'Error - La cuenta ya existe';
            this.setTime(); 
            this.isLoading = false
            return false;
          }                   
        })
        if (!registerResponse) return
        if (registerResponse.status === 200) {
          this.isLoading = false;
          this.changeCodeModalStatus();
        } 
        return registerResponse
      } catch (error) {
        this.isLoading = false;
        this.message = 'Error - La cuenta ya existe';       
        this.setTime(); 
      }
    },
    async sendConfirmCode(code){
      await axios
        .post(
          process.env.VUE_APP_URL_BACKEND + '/auth/confirmarUsuario',
          {
            code,
            email: this.user
          }
        )
        .then((response) => {
          if (response.status === 200) {
            this.loginUser();
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.message = 'Usuario o contraseña incorrectos'
            this.setTime(); 
          }
        })
    },
    async loginUser(){
      const loginResponse = await axios
        .post(
          process.env.VUE_APP_URL_BACKEND + '/auth/iniciarSesion',
          {
            password: this.password,
            usernameOrEmail: this.user
          }
        )
        .then((response) => {
          if (response.status === 200) {
            localStorage.setItem('token', response.data.tokenDeAcceso)
            const info = this.decodeJWT()
            localStorage.setItem('user', info.sub)
            if (info.roles[0] === 'ROLE_SUPERADMIN') {
              localStorage.setItem('role', 1) // 1 = superadmin
            } else {
              localStorage.setItem('role', 0) // 0 = user
            }
            localStorage.setItem('instalation', info.instalacion)
            this.$router.push({ name: 'finishRegister' })
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.message = 'Usuario o contraseña incorrectos'
            this.setTime(); 
          }
        })
      //this.isLoading = false
      return loginResponse

    },
    changeCodeModalStatus(){
      this.modalCodeStatus = !this.modalCodeStatus;
    },
    async sendCode(code){
      await this.sendConfirmCode(code);
      this.changeCodeModalStatus();
    },
    async googleRegister (responseGoogle) {
      this.isLoading = true
      const body = responseGoogle
      const headers = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      const response = await axios
        .post(
          process.env.VUE_APP_URL_BACKEND + '/auth/loginAndSignInGoogle',
          body,
          headers
        )
        .then((response) => {
          if (response.status === 200) {
            localStorage.setItem('token', response.data.tokenDeAcceso)
            const info = this.decodeJWT()
            localStorage.setItem('user', info.sub)
            if (info.roles[0] === 'ROLE_SUPERADMIN') {
              localStorage.setItem('role', 1) // 1 = superadmin
            } else {
              localStorage.setItem('role', 0) // 0 = user
            }
            localStorage.setItem('instalation', info.instalacion)
            this.$router.push('/menu')
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.message = 'Usuario o contraseña incorrectos' + error.message ? error.message : ''
          }
        })
      this.isLoading = false
      return response
    },
    decodeJWT () {
      const token = localStorage.getItem('token')
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
          })
          .join('')
      )
      return JSON.parse(jsonPayload)
    },
    changeMessageStatus(){
      this.message = '';
    },
    setTime(){
      setTimeout(() =>{
        this.message = '';
      }, 5000)
    }
  },
  components: {
    LoadingComponent,
    FooterComponent,
    ReCaptcha,
    ConfirmCode
  }
}
</script>
