import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import { createStore } from 'vuex'
import vue3GoogleLogin from 'vue3-google-login'

const store = createStore({
  state: {
    currentUser: {
      name: '',
      token: ''
    }
  },
  getters: {
    user (state) {
      return state.currentUser
    }
  },
  mutations: {
    loginUser (state, newUser) {
      if (newUser.name !== '' && typeof newUser.name === 'string') {
        state.currentUser = newUser
      }
    }
  }
})

const app = createApp(App).use(router).use(store).use(vue3GoogleLogin, {
  clientId: '643187144059-r242padc6ukomj7lvqk7hfuk8eoui250.apps.googleusercontent.com'
})
app.mount('#app')
