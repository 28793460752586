<script setup>
import { ref, computed, onMounted, toRaw, defineEmits, watch } from 'vue';
import axios from 'axios';
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/vue';
import {  CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';

const emits = defineEmits(['update:intalationSelected', 'get-nro-serie', 'update:addressName', 'update:step']);

const toSelectUser = (inst) => {
    const instalationArr = toRaw(inst);
    const devSelected = devices.value.filter(device => device?.id_inst === instalationArr[0])[0]?.device?.nro_serie;
    emits('get-nro-serie', devSelected);
    
    emits('update:intalationSelected', instalationArr[0]); //id de instalación
    emits('update:addressName', instalationArr[1]); // nombre de instalación
    emits('update:step', 2); // nombre de instalación
}

const isLoading = ref(true);
const selectedInstallation = ref(null);
const installations = ref([]);

const racks = computed(() => {
    const racks = []
    for (const installation of installations.value) {
      // Se considera instalación solo si esta pagada
      if (installation?.pagado){
        for (const rack of installation.racks) {
          if (rack.dispositivos.length > 0) {
            racks.push([installation.id, installation.nombre_instalacion + (installation.racks.length > 1 ? (' - ' + rack.ubicacion) : ''), toRaw(rack)])
          }
        }
      }
      
    }
    return racks
});

const devices = computed(() => {
  let devices = [];
  for (const installation of installations.value) {
      for (const rack of installation.racks) {
        if (rack.dispositivos.length > 0) {
          let device_obj = toRaw(installation["racks"][0]["dispositivos"][0]);
          devices.push({device: device_obj, id_inst: installation.id})
        }
      }
    }
    return devices

});

//llamar a api de instalaciones
onMounted( async () => {
    await axios
      .get(`${process.env.VUE_APP_URL_BACKEND}/instalaciones/getInstalacionesDelivery`)
      .then((response) => {
        installations.value = response.data;
        isLoading.value = false;
      });
});

watch(selectedInstallation, () => {
  toSelectUser(selectedInstallation.value); // id de instalación seleccionado
});

</script>

<template>
    <div>
        <div class="text-center">
            <h2 class="text-xl font-semibold text-white"> Seleccionar </h2>
            <h2 class="mb-5 font-semibold text-xl text-white"> Ubicación </h2>
        </div>
        
        <Listbox v-model="selectedInstallation" :disabled="isLoading" class="mt-10">
            <div class="relative mt-1">
                <ListboxButton
                    class="relative border w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm">
                    <span v-if="!isLoading" class="block truncate">{{ selectedInstallation ? selectedInstallation[1] : "Selecciona tu ubicación" }}</span>
                    <!-- spinner -->
                    <div v-if="isLoading" class="flex justify-center items-center">
                        <div
                        class="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-blue-500"
                        ></div>
                    </div>
                    <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                </ListboxButton>
                <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100"
                    leave-to-class="opacity-0">
                    <ListboxOptions
                      class="absolute mt-1 max-h-60 w-full z-20 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      <ListboxOption v-slot="{ active, selected }" v-for="installation in racks" :key="installation"
                          :value="installation " as="template">
                          <li :class="[
                          active ? 'bg-blue-100 text-blue-900' : 'text-gray-900',
                          'relative cursor-default select-none py-2 pl-10 pr-4',
                          ]">
                          <span :class="[
                              selected ? 'font-medium' : 'font-normal',
                              'block truncate',
                          ]">{{ installation[1] }}</span>
                          <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                              <CheckIcon class="h-5 w-5" aria-hidden="true" />
                          </span>
                          </li>
                      </ListboxOption>
                    </ListboxOptions>
                </transition>
            </div>
        </Listbox>
    </div>
</template>