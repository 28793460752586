<script setup>
import router from '@/router';
import AdaptableButton from './AdaptableButton.vue';

const toUserCreate = () => {
    router.push({name: 'NewAccount'});
}

// funcion redirige a usuario a login
const toUserLogin = () =>{
    router.push({name: 'Login'});
}

</script>

<template>
    <div class="space-y-8">
        <AdaptableButton
            :text="'Iniciar Sesion'"
            :bgColor="'[#FFB800]'"
            :textColor="'black'"
            @input-function="toUserLogin"
        />
        <div class="flex flex-row justify-evenly items-center my-2">
            <hr class="border-black grow mx-2" />
            <p class="text-center text-white mx-2">O</p>
            <hr class="border-black grow mx-2" />
        </div>
        <AdaptableButton
            :text="'Crear Cuenta'"
            :bgColor="'[#FFB800]'"
            :textColor="'black'"
            @input-function="toUserCreate"
        />
    </div>
</template>