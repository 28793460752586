<script setup>
import { defineEmits } from 'vue';

const emit = defineEmits(['update:codeRetry', 'update:modalCodeStatus']);

const entrerCode = () =>{
    emit('update:codeRetry', false);
    emit('update:modalCodeStatus', true);
}

const getNewCode = () =>{
    console.log("soon to implement")
    emit('update:codeRetry', false);
    //emit('update:modalCodeStatus', true);
}

</script>

<template>
    <div class="p-4 max-sm:w-80 shadow-lg border rounded-md">
        <h1 class="font-bold">Aun no confirmas tu cuenta en ODIHNX</h1>
        <h2 class="font-semibold">Porfavor selecciona para continuar</h2>

        <div class="text-center">
            <button 
                @click="getNewCode"
                :class="`w-full flex mt-8 justify-center py-2 px-4 border 
                        border-transparent rounded-md shadow-sm text-sm 
                        font-medium text-white bg-[#0064ff] hover:bg-blue-400
                        focus:outline-none focus:ring-2 focus:ring-offset-2 
                        focus:ring-blue-500`"
            >
                reenviar código por email
            </button>
            <button 
                type="submit"
                @click="entrerCode"
                :class="`w-full flex mt-8 justify-center py-2 px-4 border 
                        border-transparent rounded-md shadow-sm text-sm 
                        font-medium text-white bg-[#0064ff] hover:bg-blue-400
                        focus:outline-none focus:ring-2 focus:ring-offset-2 
                        focus:ring-blue-500`"
            >
                ingresar código previamente enviado por email
            </button>
        </div>
    </div>
</template>