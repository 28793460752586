<script setup>

</script>

<template>
    <div class="flex flex-col justify-center items-center">
        <!-- Logo candado -->
        <img class="block h-12 w-auto cursor-pointer" src="@/assets/xs_odihnx_logo.png"
            @click="$router.push('/menu')" />
        <!-- Marca Odihnx -->
        <img class="mx-auto mt-2 h-10 w-auto cursor-pointer" @click="$router.push('/')" src="@/assets/odihnx.png"
            alt="Odihnx" />
    </div>
</template>